<template>
  <div style="text-align: center; margin-top: 20px">
    <img
      src="https://i.ibb.co/d5wv1mJ/sadfsadf.png"
      width="60">
    <h2>Thank you!</h2>
    <p>Your purchase id: {{ purchaseId }}</p>
    <p>We're processing your payment and will e-mail you soon</p>
    <p>You can always track your order with the link provided in the e-mail
      <!-- <button
        class="purchasesTextButton"
        @click.stop="() => openedPage = 'purchases'"
      >
        purchases tab
      </button> -->
    </p>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage',
  methods: {
    redirectToHome () {
      this.$router.push('/')
    }
  },
  computed: {
    purchaseId () {
      const queryParams = new URLSearchParams(window.location.search)
      const id = queryParams.get('purchase')
      if (id === null || id === undefined) {
        this.redirectToHome()
      }
      return id
    }
  },
  metaInfo () {
    return {
      title: 'Thank You!',
      meta: [ { name: 'robots', content: 'noindex' } ]
    }
  }
}
</script>

<style scoped lang="stylus">

</style>
